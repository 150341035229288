//Dependency External
import { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate, useParams } from "react-router-dom"
import { Dispatch } from 'redux'
import { useSelector, useDispatch } from 'react-redux'
import { saveAs } from 'file-saver'
import { paisMap } from 'sigo-package'

//Adapters
import { AdapterConfigure } from './AdapterConfigure'
import { addLoading, hideIconMenu, removeLoading } from '../../../../shared/Infraestructure/SliceGenerico'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { RootState } from '../../../../shared/Infraestructure/AdapterStore'

//Repository
import { RepositoryImplMain } from './RepositoryImplMain'

//UseCase
import { UseCaseSelectTrabajo } from '../Application/UseCaseSelectTrabajo'

//Entities
import { ErrorCostume } from '../../../../shared/Domain/ErrorCostume'
import { UseCaseDownloadFiles } from '../Application/UseCaseDownloadFiles'
import { RepositoryMain } from '../Domain/RepositoryMain'
import { UseCaseUpdateTrabajo } from '../Application/UseCaseUpdateTrabajo'
import { UseCaseSelectTrabajoLocal } from '../Application/UseCaseSelectTrabajoLocal'
import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos'
import { EntityModal } from '../Domain/EntityModal'
import { UseCaseLoadData } from '../Application/useCaseLoadData'
import { DtoDocumentacionBaremoPEX, DtoNodoDoc } from '../../../../../app/Domain/DtoDocumentacionBaremoPEX'
import { LoadImage } from '../../../../shared/Components/ElementInputFileImage'
import { DtoFilesDoc } from '../../Trabajos/Domain/DtoFilesDoc'
import { UseCaseUploadFiles } from '../Application/UseCaseUploadFiles'

export const Controller = () => {

  const { websocket, dbLocal } = useSelector((state: RootState) => state.generico)
  const { user, userGestor } = useSelector((state: RootState) => state.auth)
  const dispatch: Dispatch = useDispatch()
  const navigate: NavigateFunction = useNavigate()
  const params = useParams<{ id: string }>()

  const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY)

  const [trabajo, setTrabajo] = useState<DtoTrabajos>({} as DtoTrabajos)
  const [dataDocumentacionBaremoPEX, setDocumentacionBaremoPEX] = useState<DtoDocumentacionBaremoPEX[]>([])
  const [showImageViewer, setShowImageViewer] = useState<boolean>(false)
  const [fileImageViewer, setFileImageViewer] = useState<File | null>(null)
  const [showButtonSave, setShowButtonSave] = useState<boolean>(false)
  const [newFilesUpload, setNewFilesUpload] = useState<File[]>([])
  useEffect(() => {
    newFilesUpload.length > 0 ? setShowButtonSave(true) : setShowButtonSave(false)
  }, [newFilesUpload])

  const {
    modalData,
    setModalData,
    documentacionBaremoPEX,
  } = ControllerModal(repository, dataDocumentacionBaremoPEX, trabajo, newFilesUpload, setNewFilesUpload)

  const init = async () => {
    try {
      dispatch(hideIconMenu())
      await loadData()
    } catch (error) {
      console.error(error)
      let err: ErrorCostume = new ErrorCostume((error as Error).message)
      AdapterGenerico.createMessage('Alerta', err.message, 'warning', false)
      navigate(AdapterConfigure.ROUTE_TRABAJOS, { replace: true })
    } finally {
      dispatch(removeLoading())
    }
  }

  const loadData = async () => {
    dispatch(addLoading({ textLoading: 'Cargando trabajo...' }))
    const trabajo = await new UseCaseSelectTrabajoLocal(repository).exec(user, Number(params.id))
    if (!trabajo) throw Error(`No se encontró trabajo con ID_Trabajo ${params.id}.`)
    setTrabajo(trabajo)

    const { DocumentacionBaremoPEX } = await new UseCaseLoadData(repository).exec(trabajo, user)
    setDocumentacionBaremoPEX(DocumentacionBaremoPEX)
  }

  const saveFiles = async () => {
    try {
      if (!Object.keys(trabajo).length) throw Error(`No Hay trabajo para actualizar.`)
      if (navigator.onLine) {
        setModalData((prev) => ({ ...prev, show: false }))
        dispatch(addLoading({ textLoading: '🛠 Actualizando Trabajo...' }))
        await new UseCaseUpdateTrabajo(repository).exec({ user, trabajo, filesUpload: newFilesUpload, userGestor })
        dispatch(removeLoading())
        setNewFilesUpload([])
        AdapterGenerico.createMessage('¡Éxito!', `Actualizado correctamente.`, 'success', false)
      } else {
        // await dbLocal.insertDataStore({ nameStore: 'CHILE_9512_Trabajos', data: NotificicacionesAceptadas })
        AdapterGenerico.createToast('Sin conexion a internet', 'error')
      }
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
    } finally {
      dispatch(removeLoading())
    }
  }

  const uploadFile = async (imagen: LoadImage[], nodo: DtoFilesDoc): Promise<any> => {
    try {
      const Extension = [imagen[0].File.name.split('.').pop()?.toLowerCase(), imagen[0].File.type.split('/')[1].trim().toLowerCase()]
      if (imagen[0].NombreOriginal !== nodo.NameOriginal || !Extension.includes(nodo.Extension.toLowerCase()))
        return AdapterGenerico.createMessage('Alerta', `Seleccione el mismo archivo que subió`, 'warning', false)
      const newFile = new File([imagen[0].File], `${trabajo.Pais.Nombre}_${trabajo.Delegacion.Codigo}_${nodo.Ruta}-${nodo.Filename}`, {
        type: imagen[0].File.type,
        lastModified: imagen[0].File.lastModified
      })
      dispatch(addLoading({ textLoading: '🛠 Actualizando Trabajo...' }))
      const result = await new UseCaseUploadFiles(repository).exec([newFile])
      if (result[0]) nodo.File = newFile
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
    } finally {
      dispatch(removeLoading())
    }
  }

  return {
    init,
    trabajo,
    loadData,
    modalData,
    setModalData,
    showButtonSave,
    saveFiles,
    documentacionBaremoPEX,
    showImageViewer,
    setShowImageViewer,
    fileImageViewer,
    setFileImageViewer,
    setNewFilesUpload,
    newFilesUpload,
    setShowButtonSave,
    uploadFile
  }
}

const ControllerModal = (repository: RepositoryMain, dataDocumentacionBaremoPEX: DtoDocumentacionBaremoPEX[], trabajo: DtoTrabajos, newFilesUpload: File[], setNewFilesUpload: React.Dispatch<React.SetStateAction<File[]>>) => {

  const dispatch: Dispatch = useDispatch()

  const [documentacionBaremoPEX, setDocumentacionBaremoPEX] = useState<DtoDocumentacionBaremoPEX | null>(null)
  const [modalData, setModalData] = useState<EntityModal>({
    show: false,
    valorizacion: null
  })

  useEffect(() => {
    const loadAdjuntosValorizacion = async () => {
      try {
        setNewFilesUpload([])
        const searchDocumentacionBaremoPEX = dataDocumentacionBaremoPEX.find(e => e.CodigoMO === modalData.valorizacion?.DetalleChile.ManoObra.Codigo)
        if (!searchDocumentacionBaremoPEX) console.warn(`No existe DocumentacionBaremoPEX para la ManoObra`)
        const defaultDocBaremo = new DtoDocumentacionBaremoPEX()
        const newNodo = new DtoNodoDoc()
        newNodo.id = 999999
        newNodo.label = 'OTROS ANEXOS'
        newNodo.Titulo = 'OTROS ANEXOS'
        newNodo.Codigo = 'Cod999999'
        newNodo.Descripcion = 'OTROS ANEXOS'
        newNodo.Extension = ['dwg', 'rar', 'pdf', 'doc', 'docx', 'txt', 'png', 'jpeg', 'jpg', 'bmp', 'webp', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'rar', 'mgs', 'gzip', 'gz', '7z', 'html', 'csv', 'avi', 'mpg', 'mkv', 'mov', 'mp4', 'wmv', 'mp3', 'wav', 'apk', "ALL"]
        newNodo.Size.Size = 50
        newNodo.Size.UM = 'MB'
        newNodo.CantidadMin = paisMap[trabajo.Pais.Codigo].CantidadMin

        defaultDocBaremo.Nodos = [newNodo]
        setDocumentacionBaremoPEX(searchDocumentacionBaremoPEX ? searchDocumentacionBaremoPEX : defaultDocBaremo)
        const fileNames: string[] = modalData.valorizacion?.Anexos.filter(e => e.Estado.ID_Estado === 1).map(e => `${trabajo.Pais.Nombre}_${trabajo.Delegacion.Codigo}_${e.Ruta}-${e.Filename}`) || []
        dispatch(addLoading({ textLoading: '📩 Descargando archivos...' }))
        const files: File[] = await new UseCaseDownloadFiles(repository).exec(fileNames)
        for (const anexo of modalData.valorizacion?.Anexos.filter(e => e.Estado.ID_Estado === 1) || []) {
          const file = files.find(e => e.name.substring(e.name.indexOf('-') + 1) === anexo.Filename)
          if (file) anexo.File = file
        }
        dispatch(removeLoading())
      } catch (error) {
        console.error(error)
        dispatch(removeLoading())
        AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
      }
    }
    modalData.show && loadAdjuntosValorizacion()
  }, [modalData.show])

  return {
    setModalData,
    modalData,
    documentacionBaremoPEX,
  }

}