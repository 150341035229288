import { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom'
import { Dispatch } from 'redux'
import { useSelector, useDispatch } from 'react-redux'
import { AdapterConfigure } from './AdapterConfigure'
import { RepositoryImplMain } from './RepositoryImplMain'
import { RootState } from '../../../../shared/Infraestructure/AdapterStore'
import {
  addLoading,
  hideIconMenu,
  removeLoading,
} from '../../../../shared/Infraestructure/SliceGenerico'
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ErrorCostume } from '../../../../shared/Domain/ErrorCostume'
import { DtoFormParteDiario } from '../Domain/DtoFormParteDiario'
import { UseCaseUpdateTrabajo } from '../Application/UseCaseUpdateTrabajo'
import { UseCaseDownloadFiles } from '../Application/UseCaseDownloadFiles'
import { EntityLoadData } from '../Domain/EntityLoadData'
import { UseCaseLoadData } from '../Application/useCaseLoadData'
import { AdapterValidator } from '../../../../shared/Infraestructure/AdapterValidator'
import { UseCaseUploadPhoto } from '../Application/UseCaseUploadPhoto'
import * as Control from './Controlador'
import { DtoParteDiario } from '../../Trabajos/Domain/DtoParteDiario'
import { UseCaseSelectTrabajoLocal } from '../Application/UseCaseSelectTrabajoLocal'
import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos'
import { LoadImage } from '../../../../shared/Components/ElementInputFileImage'
import { GetCodigoFechaActual } from 'sigo-package'

export const Controller = () => {
  const dispatch: Dispatch = useDispatch()
  const params = useParams<{ id?: string, origen?: string }>()
  const navigate: NavigateFunction = useNavigate()
  const [selectInformation, setSelectInformation] = useState<EntityLoadData>({ Personal: [], EstadoInterno: [], Cuadrillas: [] })
  const [trabajo, setTrabajo] = useState<DtoTrabajos>({} as DtoTrabajos)
  const [parteDiario, setParteDiario] = useState<DtoParteDiario>({} as DtoParteDiario)

  const { user, userGestor } = useSelector((state: RootState) => state.auth)
  const { websocket, dbLocal } = useSelector((state: RootState) => state.generico)
  const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY)

  useEffect(() => {
    if (!Object.keys(trabajo).length) return
    const restablecerParteDiario: Function = async (parteDiario: DtoParteDiario) => {
      try {
        dispatch(addLoading({ textLoading: 'Obteniendo parte diario...' }))
        await Control.RestablecerParteDiario({ selectInformation, formRegistro, UseCaseDownloadFiles, repository, parteDiario })
      } catch (error) {
        dispatch(removeLoading())
        console.error(error)
        await AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
        navigate(AdapterConfigure.ROUTE_PROGRAMADOS)
      } finally {
        dispatch(removeLoading())
      }
    }
    const codigo = params.origen ? params.origen : GetCodigoFechaActual()
    const parteDiario = trabajo.PartesDiarios.find(e => e.Codigo === codigo)
    if (!parteDiario) {
      if (params.origen) throw Error(`Error al obtener Parte Diario`)
      else return
    }
    setParteDiario(parteDiario)
    restablecerParteDiario(parteDiario)
  }, [selectInformation])

  const init = async () => {
    try {
      dispatch(hideIconMenu())
      await loadData()
    } catch (error) {
      console.error(error)
      AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
      navigate(AdapterConfigure.ROUTE_PROGRAMADOS, { replace: true })
    } finally {
      dispatch(removeLoading())
    }
  }

  const loadData = async () => {
    dispatch(addLoading({ textLoading: 'Cargando trabajo...' }))
    let trabajo = await new UseCaseSelectTrabajoLocal(repository).exec(user, Number(params.id))
    dispatch(removeLoading())
    setTrabajo(trabajo)
    dispatch(addLoading({ textLoading: 'Cargando data...' }))
    const loadData = await new UseCaseLoadData(repository).exec(user)
    dispatch(removeLoading())
    setSelectInformation(loadData)
  }

  const formRegistro = useFormik({
    initialValues: {
      Novedad: '',
      imagenes: [],
      Personal: [],
    } as DtoFormParteDiario,
    validationSchema: Yup.object({
      imagenes: Yup.array()
        .of(Yup.object())
        .required('Al menos debe adjuntar dos fotos.')
        .test('two-images', 'Fotos es requerido.', value => (value && value.length >= 2) ? true : false),
      Novedad: Yup.string()
        .matches(/^[a-zA-Z0-9 ñÑ.]*$/, 'Sólo se permiten letras, números, espacios y puntos')
        .min(5, 'Debe tener al menos 5 caracteres')
        .required('Ingrese una Novedad (al menos 5 caracteres)')
        .nullable(),
      Personal: Yup.array()
        .of(Yup.object())
        .required('Personal es rquerido.')
        .test('one-personal', 'Debes agregar un Personal como mínimo.', value => (value && value.length >= 0) ? true : false)
    }),
    onSubmit: (values, formikHelpers) => { },
  })

  const onChange = (name: string, value: any) => {
    try {
      if (value === null) { return }
      switch (name) {
        case 'deleteImg':
          formRegistro.setFieldValue(name, formRegistro.values.imagenes.splice(value, 1))
          break
        case 'imagenes':
          const ruta: string = `OBRA_${trabajo.ColeccionObras[0].ID_incidencia}_${trabajo.ID_GOM}_${trabajo.ID_Trabajo}_PartesDiarios_Fotos-`
          const newValue = value.map((imagen: LoadImage) => {
            return {
              ...imagen,
              File: new File([imagen.File], `${trabajo.Pais.Nombre}_${trabajo.Delegacion.Codigo}_${ruta}${imagen.File.name}`, {
                type: imagen.File.type,
                lastModified: imagen.File.lastModified
              })
            }
          })
          formRegistro.setFieldValue(name, [...formRegistro.values.imagenes, ...newValue])
          break
        default:
          formRegistro.setFieldValue(name, value)
          break
      }
    } catch (error) {
      console.error(error)
      let err: ErrorCostume = new ErrorCostume((error as Error).message)
      AdapterGenerico.createMessage('Alerta', err.message, 'warning', false)
    }
  }

  const onSave = async () => {
    try {
      if (!Object.keys(trabajo).length) throw Error(`No Hay trabajo para actualizar.`)
      try { await formRegistro.submitForm() } catch (error) { }
      try { AdapterValidator.validate(await formRegistro.validateForm()) } catch (error) { AdapterGenerico.createMessage('Incompleto', (error as Error).message, 'warning', false); return null }
      let data: any = { user, userGestor, trabajo, selectInformation, formRegistro }
      if (navigator.onLine) {
        dispatch(addLoading({ textLoading: '🛠 Actualizando Trabajo...' }))
        let trabajoUpdate = await new UseCaseUpdateTrabajo(repository).exec(data)
        if (trabajoUpdate.length !== 1) throw Error(`Error al reportar Parte Diario.`)
        await new UseCaseUploadPhoto(repository).exec(formRegistro.values)
        dispatch(removeLoading())
        await AdapterGenerico.createMessage('¡Éxito!', `Parte Diario reportado.`, 'success', false)
        navigate(AdapterConfigure.ROUTE_TRABAJOS)
      } else {
        // await dbLocal.insertDataStore({ nameStore: 'CHILE_9512_Trabajos', data: NotificicacionesAceptadas })
      }
    } catch (error) {
      dispatch(removeLoading())
      console.error(error)
      await AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false)
    } finally {
      dispatch(removeLoading())
    }
  }

  return {
    init,
    onChange,
    loadData,
    params,
    formRegistro,
    onSave,
    trabajo,
    selectInformation,
    parteDiario,
    user
  }
}
