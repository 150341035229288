import { EntityLoadData } from "../Domain/EntityLoadData";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { EntityDataUsuario } from "../../../../shared/Domain/EntityDataUsuario";
import { DtoTrabajos } from "../../../../Master/Home/Domain/DtoTrabajos";
import { DtoResponseSelectStockPersonal } from "../../../../Master/Home/Domain/DtoResponseSelectStockPersonal";
import { DtoItems } from "../../../../../app/Domain/DtoItems";
import { DtoDocumentacionBaremoPEX } from "../../../../../app/Domain/DtoDocumentacionBaremoPEX";

export class UseCaseLoadData {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(trabajo: DtoTrabajos, user: EntityDataUsuario): Promise<EntityLoadData> {
        const [EstadoInterno, StockPersonal, Items, DocumentacionBaremoPEX] = await Promise.all([
            this.getEstadoInterno(user),
            this.getStockPersonal(user, trabajo),
            this.getItems(user),
            this.getDocumentacionBaremoPEX(user, trabajo),
        ]);
        return ({
            EstadoInterno,
            StockPersonal,
            Items,
            DocumentacionBaremoPEX,
        })
    }

    private async getEstadoInterno(user: EntityDataUsuario): Promise<object[]> {
        const estadoIterno = await this.repository.selectEstadoInterno();
        return estadoIterno.filter(e => e.Tipo === 'Amap' && e.Pais.Codigo === user.Pais.Codigo);
    }

    private async getStockPersonal(user: EntityDataUsuario, trabajo: DtoTrabajos): Promise<DtoResponseSelectStockPersonal[]> {
        const stockPersonal = await this.repository.selectStockPersonal(user);
        return stockPersonal.filter(e => e.ID_Trabajo === trabajo.ID_Trabajo);
    }

    private async getItems(user: EntityDataUsuario): Promise<DtoItems[]> {
        const items = await this.repository.selectItems();
        return items.filter(e => e.Pais.Codigo === user.Pais.Codigo && e.Delegacion.Codigo === user.Delegacion.Codigo);
    }

    private async getDocumentacionBaremoPEX(user: EntityDataUsuario, trabajo: DtoTrabajos): Promise<DtoDocumentacionBaremoPEX[]> {
        if (trabajo.ColeccionObras.length !== 1) throw new Error(`¡Error al obtener Obra! Comunícate con Soporte`)
        const items = await this.repository.selectDocumentacionBaremoPEX();
        return items.filter(e => e.Pais.Codigo === user.Pais.Codigo && e.Delegacion.Codigo === user.Delegacion.Codigo && e.ContratoCod === trabajo.ColeccionObras[0].ContratoOT.Codigo);
    }

}