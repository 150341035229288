import { DtoTrabajos } from '../../../../Master/Home/Domain/DtoTrabajos'
import { EntityDataUsuario } from '../../../../shared/Domain/EntityDataUsuario'
import { DtoRequestUpdateTrabajo } from '../../Asignacion/Domain/DtoRequestUpdateTrabajo'
import { RepositoryMain } from '../Domain/RepositoryMain'

export class UseCaseUpdateTrabajo {
    private repository: RepositoryMain
    private user: EntityDataUsuario
    private userGestor!: EntityDataUsuario
    private trabajo: DtoTrabajos
    private filesUpload: File[]

    constructor(_repository: RepositoryMain) {
        this.repository = _repository
        this.user = {} as EntityDataUsuario
        this.trabajo = {} as DtoTrabajos
        this.filesUpload = []
    }

    public async exec(data: { user: EntityDataUsuario, userGestor: EntityDataUsuario, trabajo: DtoTrabajos, filesUpload: File[] }): Promise<DtoTrabajos[]> {
        this.user = data.user
        this.userGestor = data.userGestor
        this.trabajo = data.trabajo
        this.filesUpload = data.filesUpload
        return await this._exec()
    }

    private async _exec(): Promise<DtoTrabajos[]> {
        this.quitarFiles(this.trabajo)
        let params: DtoRequestUpdateTrabajo = {
            Header: {
                Coleccion: {
                    Codigo: this.user.Delegacion.Codigo,
                    Nombre: this.user.Pais.Nombre
                }
            },
            Body: this.trabajo
        }
        let response = await this.repository.updateTrabajo(params, this.user)
        if (response.length === 1) await this.repository.uploadPhoto(this.filesUpload)
        return response
    }

    private quitarFiles(trabajo: DtoTrabajos) {
        for (const valorizacion of trabajo.Ultima_PreLiquidacion.Valorizacion) {
            for (const anexo of valorizacion.Anexos) {
                anexo.Ultimo_Flujo.Usuario.Identificacion = this.userGestor.Identificacion
                anexo.Ultimo_Flujo.Usuario.Apellidos = `${this.userGestor.ApellidoPaterno} ${this.userGestor.ApellidoMaterno}`
                anexo.Ultimo_Flujo.Usuario.Nombres = this.userGestor.Nombres
                anexo.Ultimo_Flujo.Usuario.Fecha = new Date()
                delete anexo.File
            }
        }
    }
}